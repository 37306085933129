import { ENVIRONMENT } from "@/utils/environment";

export const HIVE_POINTS = [
  {
    image: `${ENVIRONMENT.ASSETS_URL}/hive/landing/hive-icon-network.webp`,
    text: "Get rewarded while you build your network",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/hive/landing/hive-icon-peoplehug.webp`,
    text: "Strong Creator Community",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/hive/landing/hive-icon-pencil.webp`,
    text: "Upskill Your Creator Abilities",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/hive/landing/hive-icon-coin.webp`,
    text: "Monetize Your Influence",
  },
];
