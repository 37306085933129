import { useCallback } from "react";
import BythenConnectWallet from "@/components/Header/BythenConnectWallet";

import BythenNavLogo from "./components/BythenNavLogo";
import { closeModalDialog, modalDialog } from "../layout";
import { ENVIRONMENT } from "@/utils/environment";
import { useAuthContext } from "@/contexts/auth/context";
import ButtonNav from "./components/ButtonNav";
import { cn } from "@/utils/tailwind";
import { NAV_MENU } from "./const";
import MobileCollapseMenu from "./components/MobileCollapseMenu";
import { ScreenContextProvider } from "@/pages/mycollection/contexts/ScreenContext";
import DesktopNavMenu from "./components/DesktopNavMenu";
import { APP_IOS_LINK } from "@/utils/app/link";
import NavbarDialog from "./components/NavbarDialog";
import { useRouter } from "next/router";
import Image from "next/image";
import HiveIcon from "@/assets/hive/hive-icon.svg";
import { motion } from "framer-motion";
import { useByteTrialContext } from "@/contexts/byteTrial/context";

// TODO: Add logic byte in header
// import { useQuery } from "@tanstack/react-query";
// import { getByteDetail } from "@/service/dojo/bytes";

const HEADER_THEME = {
  white: {
    "--color": "#FFFFFF",
    "--color-10": "#FFFFFF1A",
  },
  black: {
    "--color": "#0E100F",
    "--color-10": "#0E100F1A",
  },
};

export default function Header({ isBlack, bgColor, withBorder }) {
  const { authMeData, userProduct, isAuthorized } = useAuthContext();
  const { trialData } = useByteTrialContext();
  const router = useRouter();

  const handleClickNavMenu = useCallback((menu) => {
    const { target } = menu || {};
    if (target) {
      let urlTarget = "_self";
      if (target.includes("http")) urlTarget = "_blank";

      window.open(target, urlTarget);
    }
  }, []);

  const handleClickStudio = () => {
    window.open(APP_IOS_LINK, "_self");
  };

  const handleClickMyCollection = () => {
    window.open(`${ENVIRONMENT.BASE_URL}/mycollection`, "_self");
  };

  const handleClickMyAccount = () => {
    router.push("/myaccount");
    closeModalDialog();
  };

  const isShowChatRoom = trialData?.is_in_trial || userProduct?.bytes;

  const isShowHive =
    isAuthorized && (authMeData?.has_hive || userProduct?.hive);

  const handleClickChat = useCallback(() => {
    window.open(`${ENVIRONMENT.BASE_URL}/chat`, "_self");
  }, []);

  const handleClickBurgerMenu = () => {
    modalDialog.value = {
      show: true,
      placement: "bottom",
      hideCloseButton: true,
      baseClass:
        "w-full max-w-full sm:w-full sm:max-w-full sm:mx-0 md:max-w-full lg:max-w-full border-none no-scrollbar max-h-[100vh] md:max-h-[100vh] rounded-t-[12px] md:rounded-t-[12px] md:rounded-t-[16px] md:rounded-b-none md:mx-0",
      bodyClass:
        "rounded-t-[12px] md:rounded-t-[12px] md:rounded-b-0 bg-white border-none px-4 py-8",

      body: (
        <ScreenContextProvider>
          <MobileCollapseMenu
            onClickChat={handleClickChat}
            onClickMyCollection={handleClickMyCollection}
            onClickMyAccount={handleClickMyAccount}
            onClickNavMenu={handleClickNavMenu}
            onClickStudio={handleClickStudio}
          />
        </ScreenContextProvider>
      ),
    };
  };

  return (
    <>
      <header
        style={HEADER_THEME[isBlack ? "black" : "white"]}
        className={cn(
          `fixed top-0 z-[49] w-full text-[--color] [&_.menu-item:after]:bg-[--color] [&_.menu-item:before]:bg-[--color]`,
          bgColor,
          withBorder && "border-b-1 md:pb-8",
          "px-[12px] py-[20px] md:px-[24px] md:py-[20px]",
        )}
      >
        <div className="m-auto flex w-full max-w-[1920px] items-center justify-between space-x-4 transition-all">
          <div className="flex items-center space-x-4">
            <BythenNavLogo isBlack={isBlack} />

            {/* Desktop nav menu */}
            <div className="hidden font-founders_semibold text-base lg:flex">
              {NAV_MENU.map((menu, index) => (
                <DesktopNavMenu
                  key={index}
                  menu={menu}
                  onClickNavMenu={handleClickNavMenu}
                />
              ))}
            </div>
          </div>

          <div className="flex items-center">
            {/* Studio Desktop */}
            {isAuthorized && (
              <NavbarDialog
                dialogContainerClass="w-[240px] left-[-110%]"
                dialogClass="p-4"
                dialog={
                  <>
                    <div className="absolute -top-4 left-[55%] h-4 w-4 bg-byteBlack-1000 [clip-path:url(#clip-pointer)]"></div>
                    <div className="absolute -top-[14.5px] left-[55%] h-4 w-4 bg-white [clip-path:url(#clip-pointer)]"></div>
                    <p className="text-byteBlack-1000">
                      Studio feature is currently only available on iOS.
                    </p>
                  </>
                }
              >
                <ButtonNav isBlack={false} className={"hidden lg:flex"}>
                  <span className="material-symbols-outlined !-mb-[2px] !text-[16px]">
                    videocam
                  </span>
                  <span>STUDIO</span>
                </ButtonNav>
              </NavbarDialog>
            )}

            {/* Chat button Desktop */}
            {isShowChatRoom && (
              <ButtonNav
                isBlack={false}
                onClick={handleClickChat}
                className="ml-3 flex"
              >
                <span className="material-symbols-outlined !-mb-[2px] !text-[16px]">
                  chat_bubble
                </span>
                <span>CHATROOM</span>
              </ButtonNav>
            )}

            {isShowHive && (
              <div className="relative ml-3 w-20">
                <motion.div
                  style={{
                    filter: "blur(6px)",
                    WebkitBackfaceVisibility: "hidden",
                    MozBackfaceVisibility: "hidden",
                    WebkitTransform: "translate3d(0, 0, 0)",
                    MozTransform: "translate3d(0, 0, 0)",
                  }}
                  className="absolute left-[-2px] top-[-2px] z-[-1] h-[calc(100%+4px)] w-[calc(100%+4px)] rounded-full"
                  animate={{
                    background: [0, 360].map(
                      (deg) =>
                        `linear-gradient(${deg}deg, #FFE135 0%, #F04406 100%)`,
                    ),
                  }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 2,
                    ease: "linear",
                  }}
                ></motion.div>
                <button
                  onClick={() => window.open("/byteshive", "_self")}
                  className={cn(
                    "relative overflow-visible rounded-[100px] border border-white/40 bg-byteBlack-1000 px-3 py-[10px] hover:bg-[#1a1c1b]",
                  )}
                >
                  <div className="mx-auto flex items-center space-x-2 font-founders_semibold text-sm leading-none text-white">
                    <Image
                      src={HiveIcon.src}
                      width={16}
                      height={16}
                      unoptimized
                      alt="hive icon navbar"
                    />
                    <span>HIVE</span>
                  </div>
                </button>
              </div>
            )}

            {/* Separator */}
            {isAuthorized && (
              <div className="ml-3 hidden h-9 border-r-1 text-[--color-10] lg:block" />
            )}

            {/* Desktop connect and disconnect */}
            <BythenConnectWallet
              isBlack={isBlack}
              onClickMyCollection={handleClickMyCollection}
              onClickMyAccount={handleClickMyAccount}
            />

            {/* Burger menu mobile */}
            <button
              className="ml-1 flex items-center p-1 lg:hidden"
              onClick={handleClickBurgerMenu}
            >
              <span className="material-symbols-outlined !text-[24px]">
                menu
              </span>
            </button>
          </div>
        </div>
      </header>
    </>
  );
}
