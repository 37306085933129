import React from "react";
import Image from "next/image";
import { ENVIRONMENT } from "@/utils/environment";
import discordLogo from "@/assets/discord.svg";
import twitterLogo from "@/assets/twitter.svg";
import ArrowRightIcon from "../Icon/ArrowRightIcon";
import Button from "../Button";
import { FOOTER_MENUS } from "./const";
import { cn } from "@/utils/tailwind";

export default function Footer() {
  const onOpenDiscord = () => {
    window.open("https://discord.gg/bythenai", "_blank");
  };

  const onOpenX = () => {
    window.open("https://twitter.com/bythenAI", "_blank");
  };

  const onOpenBlur = () => {
    window.open("https://blur.io/eth/collection/bythen-chip", "_blank");
  };

  return (
    <div className="bg-byteBlack-1000">
      <div className="mx-auto max-w-[1920px]">
        <div className="flex flex-col space-y-8 px-4 py-10 md:flex-row md:justify-between md:space-y-0 md:px-6 md:pb-16 md:pt-14">
          <div className="w-full md:mr-8 md:max-w-[22%]">
            <p className="font-founders_semibold text-[32px] uppercase leading-[28px] tracking-tight text-white md:text-[min(4.5vw,_56px)] md:leading-[0.8] lg:text-[56px]">
              Stay
              <br />
              Connected
            </p>
            <p className="mt-4 block text-sm text-white md:hidden">
              Follow our socials to make sure you don&apos;t miss out on our
              latest updates and announcements
            </p>
          </div>

          <div className="grid w-full grow grid-cols-2 gap-x-3 gap-y-1 self-baseline transition-all lg:grid-cols-3">
            {FOOTER_MENUS.map((menu, menuIndex) => {
              const column = (menuIndex % 2) + 1;

              return (
                <a
                  href={menu.href}
                  key={menuIndex}
                  className={cn(
                    "inline-block h-fit font-founders_semibold uppercase leading-none text-white md:text-[min(1.5vw,_18px)] lg:justify-self-start",
                    column == 2 && "justify-self-end",
                  )}
                >
                  {menu.label}
                </a>
              );
            })}
          </div>

          <div className="w-full transition-all md:ml-8 md:max-w-[35%]">
            <p className="mb-4 mt-0 hidden text-[min(1.5vw,_16px)] text-white md:block">
              Follow our socials to make sure you don&apos;t miss out on our
              latest updates and announcements
            </p>

            <div className="flex flex-col space-x-0 space-y-[16px] md:flex-row md:space-x-[12px] md:space-y-0 xl:space-x-[19px]">
              <Button
                type="black"
                classNames="font-founders_semibold text-white tracking-wide text-[16px] cursor-pointer border-1 flex justify-between md:justify-center items-center border-white px-[16px] py-[14px] md:px-[14px] max-h-12"
                onClickButton={onOpenX}
              >
                <p className="block md:hidden">FOLLOW US ON X</p>
                <Image
                  unoptimized
                  width={20}
                  height={20}
                  src={twitterLogo.src}
                  alt="x"
                  className="h-auto md:w-[min(1.5vw,_20px)]"
                />
              </Button>
              <Button
                type="black"
                classNames="font-founders_semibold text-white tracking-wide text-[16px] cursor-pointer border-1 flex justify-between md:justify-center items-center border-white px-[16px] py-[14px] md:px-[14px] max-h-12"
                onClickButton={onOpenDiscord}
              >
                <p className="block md:hidden">FOLLOW US ON DISCORD</p>
                <Image
                  unoptimized
                  width={20}
                  height={20}
                  src={discordLogo.src}
                  alt="discord"
                  className="h-auto md:w-[min(1.5vw,_20px)]"
                />
              </Button>
              <Button
                type="black"
                classNames="font-founders_semibold text-white tracking-wide text-[16px] cursor-pointer border-1 flex justify-between md:justify-center items-center border-white px-[16px] py-[14px] md:px-[14px] max-h-12"
                onClickButton={onOpenBlur}
              >
                {/* <div className={`${footerButtonClass} md:px-[24px]`}> */}
                <p className="mr-[9.67px] shrink-0 leading-none md:text-[min(1.5vw,_20px)]">
                  FIND US ON BLUR
                </p>
                <div className="flex aspect-square w-[20px] items-center">
                  <ArrowRightIcon color="white" />
                </div>
                {/* </div> */}
              </Button>
            </div>
          </div>
        </div>
        <div className="mx-0 border-t-1 md:mx-[24px]"></div>

        <div className="flex justify-between px-[16px] pb-[27px] pt-[20px] text-white md:px-[24px] md:pb-[36px] md:pt-[30px]">
          <div className="relative aspect-[76/20] w-full max-w-[76px] md:max-w-[91.58px]">
            <Image
              unoptimized
              src={`${ENVIRONMENT.ASSETS_URL}/landing/logo_byten.svg`}
              alt="logo"
              fill
            />
          </div>
          <div className="text-[12px] md:text-[14px]">© 2024 bythen</div>
        </div>
      </div>
    </div>
  );
}
