import React, { useCallback, useRef, useState } from "react";
import Image from "next/image";
import { ENVIRONMENT } from "@/utils/environment";
import { cn } from "@/utils/tailwind";
import useSectionWithNavbar from "../hooks/useSectionWithNavbar";

const MembersData = [
  {
    name: "Kevin",
    space: "@FireMosu",
    background:
      "Serial founder with 2x successful exits to NYSE: WPP and IDX: GOTO, and member of Spirit DAO.",
    image_url: `${ENVIRONMENT.ASSETS_URL}/landing/team/kevin.webp`,
  },
  {
    name: "Erick",
    space: "@diskotep",
    background:
      "Product by day, weeb by night. EX-WPP & GoTo, self-proclaimed professional tourist.",
    image_url: `${ENVIRONMENT.ASSETS_URL}/landing/team/erick.webp`,
  },
  {
    name: "Ferry",
    space: "@amankz_",
    background:
      "Ex Goto. Gamer at heart, fueled by coffee and noodles, and the unifying force behind the engineering team.",
    image_url: `${ENVIRONMENT.ASSETS_URL}/landing/team/ferry.webp`,
  },
  {
    name: "Dora",
    space: "@nathisadora",
    background:
      "Building brands through marketing and partnership. Ex-GoTo and Bytedance.",
    image_url: `${ENVIRONMENT.ASSETS_URL}/landing/team/nathalia.webp`,
  },
  {
    name: "William",
    space: "@nagataxyz",
    background:
      "MBA HKUST-Cornell U, previously worked in Shopee SEA specializing in business development.",
    image_url: `${ENVIRONMENT.ASSETS_URL}/landing/team/william.webp`,
  },
];

const HOVER_CLASS =
  "hover:shadow-[6px_6px_0px_0px_#0E100F] hover:border-[#0E100F] hover:scale-[1.01] transition-all duration-300";

const MemberItem = ({ name, space, background, image_url, num }) => {
  const onClickCard = useCallback(() => {
    window.open(`https://www.twitter.com/${space}`, "_blank");
  }, [space]);
  return (
    <div
      onClick={onClickCard}
      className={`min-w-[238px] cursor-pointer snap-start border border-[#0E100F] bg-[#FFFFFF] md:w-[22%] md:shrink-0 ${HOVER_CLASS}`}
    >
      <div className="aspect-square w-full max-w-full overflow-hidden">
        <Image
          unoptimized
          className="w-full object-cover"
          src={image_url}
          alt="member"
          width={242}
          height={242}
        />
      </div>
      <div className="px-4 py-5">
        {/* <p className="font-mono text-sm font-medium leading-4 text-[#0E100F]">
          CO-FOUNDER #{num}
        </p> */}

        <div className="mt-7 flex-grow font-founders_semibold text-[28px] uppercase leading-[28px] md:text-[32px] md:leading-8">
          {name}
        </div>
        <div className="mt-[6px] w-[56px] flex-grow text-xs text-[#0E100F99] md:mt-2 md:text-sm">
          {space}
        </div>
        <div className="mt-4 text-xs text-[#0E100F] md:text-base">
          {background}
        </div>
      </div>
    </div>
  );
};
function SectionTeam() {
  const sectionTeam = useSectionWithNavbar({ isBlack: true });
  const scrollRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      const scrollAmount = 500; // Adjust this value for the scroll step

      let newScrollPosition =
        direction === "next"
          ? scrollLeft + scrollAmount
          : scrollLeft - scrollAmount;

      scrollRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });

      // Update state after a small delay to account for smooth scrolling
      setTimeout(() => {
        const newScrollLeft = scrollRef.current.scrollLeft;
        setIsAtStart(newScrollLeft === 0);
        setIsAtEnd(newScrollLeft + clientWidth >= scrollWidth);
      }, 300); // Delay matches the scroll behavior
    }
  };

  return (
    <section ref={sectionTeam} className="bg-[#0E100F0D]">
      <div className="mx-auto max-w-[1920px] py-10 md:py-20">
        <div className="mb-4 px-4 text-center md:mb-12 md:flex-row md:px-16">
          <h3 className="w-full font-founders_semibold text-[56px] leading-[44px] tracking-[0.1px] text-[#0E100F] md:-mt-2 md:text-[min(10vw,_96px)] md:leading-none">
            OUR TEAM
          </h3>
          {/* <p className="mt-4 w-full text-sm tracking-[0.2px] md:ml-5 md:mt-0 md:max-w-[520px] md:text-lg lg:ml-[max(2vw,_150px)]">
            We&apos;re a group of entrepreneurs, engineers and creatives, united
            by our love for technology and real human connections. Our goal is
            to create innovative solutions that bring people and technology
            closer.
          </p> */}
        </div>
        <div
          ref={scrollRef}
          className="no-scrollbar flex snap-x scroll-pl-4 gap-4 overflow-scroll px-4 py-2 md:scroll-pl-16 md:gap-5 md:px-16 md:py-4"
          onScroll={() => {
            if (scrollRef.current) {
              const { scrollLeft, scrollWidth, clientWidth } =
                scrollRef.current;
              setIsAtStart(scrollLeft === 0);
              setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
            }
          }}
        >
          {MembersData.map((member, i) => (
            <MemberItem {...member} num={i + 1} key={member.name} />
          ))}
        </div>

        <div className="flex items-center justify-end gap-5 px-4 pt-5 md:px-16 md:pt-10">
          <button
            onClick={() => scroll("prev")}
            disabled={isAtStart}
            className={cn(
              "p-0 text-byteBlack-1000",
              isAtStart && "cursor-not-allowed opacity-50",
            )}
          >
            <i aria-hidden className="fa-sharp fa-arrow-left text-xl" />
          </button>
          <button
            onClick={() => scroll("next")}
            disabled={isAtEnd}
            className={cn(
              "p-0 text-byteBlack-1000",
              isAtEnd && "cursor-not-allowed opacity-50",
            )}
          >
            <i aria-hidden className="fa-sharp fa-arrow-right text-xl" />
          </button>
        </div>
      </div>
    </section>
  );
}

export default SectionTeam;
