import { useCallback, useMemo, useState } from "react";
import { ByteTrialCountdownContext } from "./context";
import { putTrialBytes } from "@/service/store";
import ModalEndTrial from "./ModalEndTrial";
import { getModalProps } from "@/components/Modal/ModalDialog/const";
import { closeModalDialog, modalDialog } from "@/components/layout";
import { useEffect } from "react";
import { useByteTrialContext } from "../byteTrial/context";

const getStorePdpUrl = (data) => {
  const { trial } = data || {};
  // const tokenId = trial?.original_token_id;
  const symbol = trial?.original_token_symbol ?? "";

  return `/store/${symbol}`;
};

const ByteTrialCountdownProvider = ({ children, endTrialEntryPoint }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [isShowEndTrialModal, setIsShowEndTrialModal] = useState(false);

  const { trialData } = useByteTrialContext();

  useEffect(() => {
    setTimeLeft(trialData?.countdown ?? 0);
    setIsShowEndTrialModal(!!trialData?.is_show_end_trial_modal);
  }, [trialData]);

  const resetFreeTrial = useCallback(async () => {
    try {
      await putTrialBytes({
        is_end_dialogue_displayed: true,
      });
    } catch (e) {
    } finally {
      setIsShowEndTrialModal(false);
      closeModalDialog();
    }
  }, []);

  const onCloseModalEndTrial = useCallback(() => {
    resetFreeTrial();
    if (endTrialEntryPoint) {
      window.location.href = endTrialEntryPoint;
    } else {
      window.location.href = "/store";
    }
  }, [endTrialEntryPoint, resetFreeTrial]);

  const onBuy = useCallback(() => {
    resetFreeTrial();
    const storePdpUrl = getStorePdpUrl(trialData);
    window.location.href = storePdpUrl;
  }, [trialData, resetFreeTrial]);

  useEffect(() => {
    if (isShowEndTrialModal) {
      modalDialog.value = {
        ...getModalProps({
          backgroundClose: false,
          bodyClassName: "p-0 md:p-0",
          isMobileBottomSheet: false,
          isDesktopWidthFixed: false,
          hideCloseButton: true,
        }),
        body: (
          <ModalEndTrial
            onBuy={onBuy}
            onClose={onCloseModalEndTrial}
            trialData={trialData}
          />
        ),
        isKeyboardDismissDisabled: true,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowEndTrialModal, onCloseModalEndTrial, onBuy]);

  useEffect(() => {
    if (timeLeft <= 0) {
      return;
    }

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 1) {
          setIsShowEndTrialModal(true);
        }
        return Math.max(prevTime - 1, 0);
      }); // Reduce time by 1 second
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [timeLeft]); // Rely on the internal state

  const value = useMemo(
    () => ({
      timeLeft,
    }),
    [timeLeft],
  );
  return (
    <ByteTrialCountdownContext.Provider value={value}>
      {children}
    </ByteTrialCountdownContext.Provider>
  );
};

export default ByteTrialCountdownProvider;
