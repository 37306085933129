import { Button } from "@nextui-org/react";
import { isMobile } from "react-device-detect";
import useSectionWithNavbar from "../../hooks/useSectionWithNavbar";

import HivePointsDesktop from "./components/HivePoints/desktop";
import HivePointsMobile from "./components/HivePoints/mobile";
import { ENVIRONMENT } from "@/utils/environment";
import Image from "next/image";
import dynamic from "next/dynamic";

const PlayBoxVideo = dynamic(() => import("@/components/PlayBoxVideo"), {
  ssr: false,
});

export default function SectionByteHive() {
  const sectionByteHiveRef = useSectionWithNavbar({ isBlack: false });

  return (
    <div
      ref={sectionByteHiveRef}
      style={{
        "--title-size": "min(96px,max(calc((96/1440) * 100vw),64px))",
        "--title-leading": "min(96px,max(calc((96/1440) * 100vw),44px))",
        "--title-pb": "min(80px,max(calc((80/1440) * 100vw),48px))",
      }}
      className="relative z-[1] bg-byteBlack-1000 pb-10 pt-[70vw] md:pb-[115px] md:pt-[max(20vw,280px)]"
    >
      <div className="absolute left-0 top-0 -z-[1] h-full w-full">
        <Image
          unoptimized
          src={`${ENVIRONMENT.ASSETS_URL}/landing/hive-lg-bg-desktop.webp`}
          fill
          alt="bg-hive-landing"
          className="hidden object-cover object-top md:block"
        />
        <Image
          unoptimized
          src={`${ENVIRONMENT.ASSETS_URL}/landing/hive-lg-bg-mobile.webp`}
          fill
          alt="bg-hive-landing"
          className="block object-cover object-top md:hidden"
        />
      </div>

      <div className="relative">
        <PlayBoxVideo
          playButtonClassName="md:h-[90px] md:w-[90px] md:text-[48px] text-[36px] h-[64px] w-[64px] [&_i]:ml-3"
          className="relative flex h-[326px] w-full items-center justify-center overflow-hidden md:m-auto md:mb-[70px] md:h-[384px] md:w-[800px]"
          thumbnailDesktop={`${ENVIRONMENT.ASSETS_URL}/landing/cornerstone-thumbnail-desktop.webp`}
          thumbnailMobile={`${ENVIRONMENT.ASSETS_URL}/landing/cornerstone-thumbnail-mobile.webp`}
          thumbnailOverlayClass={"h-full w-full bg-byteBlack-1000/20"}
          videoSrc="https://storage.bythen.ai/byteshive-tutorial.webm"
        />
      </div>

      {!isMobile && (
        <p className="m-auto w-[500px] text-center text-white">
          Join Bytes Hive, Bythen&apos;s community leveraging a revenue sharing
          model, where the majority of Bythen&apos;s revenue is distributed
          daily to members—driving collective success.
        </p>
      )}

      {isMobile ? <HivePointsMobile /> : <HivePointsDesktop />}

      <div className="px-[16px] md:m-auto md:w-[414px]">
        <Button
          onPress={() => window.open("/about-byteshive", "_blank")}
          radius="none"
          endContent={
            <span className="material-symbols-outlined absolute right-3 text-base">
              arrow_forward
            </span>
          }
          className="w-full justify-start bg-white py-[16px] font-founders_semibold text-base md:justify-center md:text-lg"
        >
          LEARN MORE
        </Button>
      </div>
    </div>
  );
}
