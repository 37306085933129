import Cookies from "js-cookie";

export const COOKIE_HIVE_REFERRAL = "REFERRAL_HIVE";

export const getHiveReferralCookie = () => {
  if (!Cookies.get(COOKIE_HIVE_REFERRAL)) return "";

  try {
    const result = Cookies.get(COOKIE_HIVE_REFERRAL) || "";
    return result === "undefined" ? "" : result;
  } catch (e) {
    return "";
  }
};

export const setHiveReferralCookie = (referral) => {
  Cookies.set(COOKIE_HIVE_REFERRAL, referral, {
    expires: 365 * 2,
  });
};

