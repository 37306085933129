import fetchApi from "@/service/fetchApi";

export async function getSignatureCard(body) {
  return fetchApi("ms/web3/v1/cards/signature", {
    method: "POST",
    body,
  });
}

export async function getProductEligibility(body) {
  return fetchApi("ms/web3/v1/accounts/products/eligibility");
}

export function getSignaturePodERC20(body) {
  return fetchApi(`ms/web3/v1/pod_erc20/signature`, { method: "POST", body });
}

export function getSignaturePodERC721(body) {
  return fetchApi(`ms/web3/v1/pod/signature`, { method: "POST", body });
}
