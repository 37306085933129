import Image from "next/image";

import SectionCarousel from "../SectionCarousel";
import SectionCarouselItem from "../SectionCarousel/SectionCarouselItem";

import { HIVE_POINTS } from "../../constants";
import { Fragment } from "react";

export default function HivePointsMobile() {
  return (
    <SectionCarousel>
      {HIVE_POINTS.map((item, index) => (
        <Fragment key={index}>
          <SectionCarouselItem className="aspect-auto">
            <div className="px-[28px] text-center">
              <Image
                unoptimized
                src={item.image}
                alt={item.text}
                className="m-auto pb-[12px]"
                width={120}
                height={120}
              />
              <p className="text-white">{item.text}</p>
            </div>
          </SectionCarouselItem>
        </Fragment>
      ))}
    </SectionCarousel>
  );
}
