import { cn } from "@/utils/tailwind";

const MODAL_SIZE = {
  md: {
    baseClass: "md:max-w-[458px]",
  },
  lg: {
    baseClass: "md:max-w-[608px]",
  },
  xl: {
    baseClass: "md:max-w-[708px]",
  },
};

const getMobileClass = ({ isMobileBottomSheet, size }) => {
  const MODAL_SIZE_MOBILE = {
    md: {
      baseClass: "max-w-[458px] sm:max-w-[458px]",
    },
    lg: {
      baseClass: "max-w-[608px] sm:max-w-[608px]",
    },
    xl: {
      baseClass: "max-w-[708px] sm:max-w-[708px]",
    },
  };

  const { baseClass: baseClassSize } = MODAL_SIZE_MOBILE[size];

  return {
    baseClass: `${isMobileBottomSheet ? `w-full max-w-full sm:w-full sm:max-w-full sm:mx-0 md:rounded-none mt-auto sm:mt-auto md:my-auto` : `rounded-none w-[90%] sm:my-auto my-auto ${baseClassSize}`}`,
    bodyClass: `${isMobileBottomSheet ? "rounded-t-[8px]" : "md:rounded-none rounded-none sm:rounded-none"}`,
  };
};

/**
 * Is a helper function to get modal props with breakpoint 768px for desktop
 * size: modal width maxWidth in MODAL_SIZE
 * isDesktopWidthFixed: if true, the modal width will be fixed based on maxWidth in MODAL_SIZE
 * isMobileBottomSheet: if true, the modal in screen < 768px, will be displayed at the bottom of the screen
 *
 * Default config from modal dialog component
 * hideCloseButton = false,
 * backgroundClose = true,
 */

const DEFAULT_PROPS = {
  size: "md",
  isDesktopWidthFixed: true,
  isMobileBottomSheet: false,
  hideCloseButton: false,
  backgroundClose: true,
  backdrop: "",
  baseClassName: "",
  bodyClassName: "",
  backdropClassName: "",
};
export const getModalProps = (props = DEFAULT_PROPS) => {
  const {
    size = DEFAULT_PROPS.size,
    isDesktopWidthFixed = DEFAULT_PROPS.isDesktopWidthFixed,
    isMobileBottomSheet = DEFAULT_PROPS.isMobileBottomSheet,
    hideCloseButton = DEFAULT_PROPS.hideCloseButton,
    backgroundClose = DEFAULT_PROPS.backgroundClose,
    backdrop = "opaque",
    baseClassName = "",
    bodyClassName = "",
    backdropClassName = "",
  } = props || {};

  const { baseClass: baseClassDesktop } = MODAL_SIZE[size];
  const { baseClass: baseClassMobile, bodyClass: bodyClassMobile } =
    getMobileClass({ isMobileBottomSheet, size });

  return {
    show: true,
    backgroundClose,
    hideCloseButton,
    backdropClassName: cn("z-[999]", backdropClassName),
    wrapperClassName: "z-[999]",
    backdrop: backdrop,
    baseClass: cn(
      `${baseClassMobile} border-none no-scrollbar max-h-[100vh] md:max-h-[100vh] ${baseClassDesktop} ${!isDesktopWidthFixed ? "md:w-fit" : ""}`,
      baseClassName,
    ),
    bodyClass: cn(
      `${bodyClassMobile} bg-white border-none md:pb-10 px-7 pb-7 md:px-8 md:pb-8 ${hideCloseButton ? "pt-7 md:pt-8" : "pt-12 md:pt-[72px]"}`,
      bodyClassName,
    ),
  };
};
