import { useContext, useMemo, useState, createContext } from "react";

const BythenNavbarContext = createContext({
  isBlack: true,
  setIsBlack: () => {},
});

export const BythenNavbarProvider = ({ children }) => {
  const [isBlack, setIsBlack] = useState(true);

  const value = useMemo(() => ({ isBlack, setIsBlack }), [isBlack, setIsBlack]);

  return (
    <BythenNavbarContext.Provider value={value}>
      {children}
    </BythenNavbarContext.Provider>
  );
};

export const useBythenNavbarContext = () => useContext(BythenNavbarContext);
