import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import {
  motion,
  useScroll,
  useMotionValueEvent,
  useAnimate,
  useInView,
} from "framer-motion";
import { Play } from "@phosphor-icons/react";
import { ENVIRONMENT } from "@/utils/environment";
import Button from "@/components/Button";
import { SectionContentContainer } from "./components/SectionContentContainer";
import { SectionUseCaseHeader } from "./components/SectionUseCaseHeader";
import useSectionWithNavbar from "../../hooks/useSectionWithNavbar";
import TextRotateFromBottom from "../Animation/TextRotateFromBottom";
import { cn } from "@/utils/tailwind";

const renderDesc = (title, desc, titleClassName = "") => (
  <div>
    <p
      className={cn(
        `mb-[8px] text-[18px] font-medium leading-[24px] min-[1366px]:mb-[12px] min-[1366px]:text-[20px] min-[1366px]:leading-[28px] [@media(max-height:768px)]:text-[18px] [@media(max-height:768px)]:leading-[24px]`,
        titleClassName,
      )}
      dangerouslySetInnerHTML={{ __html: title }}
    ></p>
    <p
      className={`text-[14px] leading-[20px] tracking-[0.02em] min-[1366px]:text-[16px] min-[1366px]:leading-[24px] [@media(max-height:768px)]:text-[14px] [@media(max-height:768px)]:leading-[20px]`}
    >
      {desc}
    </p>
  </div>
);

const SectionUseCaseA = ({ isAnimate }) => {
  const [, animate] = useAnimate();
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const contentGridRef = useRef(null);
  const whiteFilterRef = useRef(null);
  const isInView = useInView(titleRef, { once: true });
  isAnimate = isAnimate ?? isInView;

  useEffect(() => {
    if (isAnimate) {
      animate([
        [descRef.current, { opacity: [0, 1] }, { delay: 0.7 }],
        [contentGridRef.current, { opacity: [0, 1] }, { duration: 0.7 }],
        [
          whiteFilterRef.current,
          { height: ["100%", "1%"] },
          { duration: 0.7, at: "<" },
        ],
      ]);
    }
  }, [isAnimate]);

  return (
    <>
      <SectionUseCaseHeader
        className="mb-[24px] px-4 pt-10 sm:px-[min(5%,64px)] lg:mb-[16px] lg:p-0"
        title={
          <span className="uppercase" ref={titleRef}>
            <TextRotateFromBottom animate={isAnimate ? "animate" : ""}>
              Autonomous Digital Twin
            </TextRotateFromBottom>
          </span>
        }
        desc={
          <div ref={descRef}>
            <p>
              Bytes is your fully customizable digital twin, tailored to reflect
              your alter ego or an extension of yourself.
            </p>
          </div>
        }
      />

      <div className="relative h-full w-full [@media(max-height:768px)_and_(min-width:1366px)]:-mb-4">
        <div
          ref={contentGridRef}
          className="relative mt-11 grid h-auto w-full grid-cols-[repeat(3,minmax(238px,1fr))] grid-rows-[minmax(0,1fr)_0fr] gap-[16px] overflow-auto px-4 pb-10 sm:px-[min(5%,64px)] md:mt-[min(16px,3vw)] md:gap-x-[24px] lg:p-0"
        >
          <div className="relative md:max-h-[300px]">
            <Image
              width={552}
              height={468}
              unoptimized
              src={`${ENVIRONMENT.ASSETS_URL}/landing/use-case/usecase-1.webp`}
              className="h-full w-full object-contain object-top"
              alt="img-section-a-1"
            />
          </div>

          <div className="relative md:max-h-[300px]">
            <div className="absolute left-0 top-0 h-full w-5 bg-gradient-to-r from-white"></div>
            <div className="absolute right-0 top-0 h-full w-5 bg-gradient-to-l from-white"></div>
            <video
              autoPlay
              preload="auto"
              playsInline
              loop
              muted
              className="h-full w-full object-contain"
            >
              <source
                src={`${ENVIRONMENT.STORAGE_URL}/FRAME-2.webm`}
                type="video/webm"
              />
            </video>
          </div>

          <div className="relative md:max-h-[300px]">
            <Image
              width={552}
              height={468}
              unoptimized
              src={`${ENVIRONMENT.ASSETS_URL}/landing/use-case/usecase-3.webp`}
              className="h-full w-full object-contain object-top"
              alt="img-section-a-1"
            />
          </div>

          {renderDesc(
            `Fully Customizable Persona`,
            `Personalize your Bytes knowledge, memories, origin, voice, and backstory. Create a Bytes that is uniquely yours or a digital copy of your personality`,
          )}
          {renderDesc(
            `Cross Platform AI Agent`,
            `Bytes operates 24/7 as an AI agent on social media platforms like X and TikTok, autonomously generate content and replies that reflect its personality.`,
          )}
          {renderDesc(
            `<span>Cross Media Compactibility</span> <span class="ml-2 mt-[1px] font-founders_semibold text-[10px] leading-4 p-1 bg-sonicBlue-600 uppercase text-white tracking-wide">COMING SOON!</span>`,
            `Fully interoperable, bytes integrates seamlessly across websites, apps, VR, and gaming worlds, effortlessly extending your reach and influence.`,
            "flex items-center",
          )}
        </div>

        <div
          ref={whiteFilterRef}
          className="pointer-events-none absolute bottom-0 left-0 h-full w-full select-none bg-white"
        />
      </div>
    </>
  );
};

const SectionUseCaseB = ({ isAnimate }) => {
  const [, animate] = useAnimate();
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const isInView = useInView(titleRef, { once: true });
  isAnimate = isAnimate ?? isInView;

  useEffect(() => {
    if (isAnimate) {
      animate([
        [descRef.current, { opacity: [0, 1] }],
        [
          contentRef1.current,
          { opacity: [0, 1] },
          { duration: 0.7, at: "-0.3" },
        ],
        [
          contentRef2.current,
          { opacity: [0, 1] },
          { duration: 0.7, at: "-0.3" },
        ],
      ]);
    }
  }, [isAnimate]);

  return (
    <>
      <SectionUseCaseHeader
        className="mb-[24px] items-center md:mb-[44px]"
        title={
          <span ref={titleRef}>
            <TextRotateFromBottom animate={isAnimate ? "animate" : ""}>
              CONTENT GENERATION TOOL
            </TextRotateFromBottom>
          </span>
        }
        desc={
          <p ref={descRef}>
            Effortlessly create high-quality videos and images with our advanced
            AI tools—just input your script, and our platform auto-generates
            your content.
          </p>
        }
      />

      <div className="relative flex h-full w-full items-start justify-center">
        <div className="block h-full w-full items-center justify-center md:grid md:w-auto md:grid-cols-[minmax(0,1fr)_0.5fr] md:grid-rows-1 md:gap-[48px]">
          <div
            ref={contentRef1}
            className="relative aspect-[738/472] h-full w-full"
          >
            <Image
              fill
              unoptimized
              src={`${ENVIRONMENT.ASSETS_URL}/landing/use-case/usecase-4.webp`}
              className="h-full w-full object-contain"
              alt="illust-section-c-1"
            />
          </div>
          <div
            ref={contentRef2}
            className="mb-[6px] mt-[28px] flex w-full flex-col md:mb-0 md:mt-0 md:w-[366px]"
          >
            {[
              {
                icon: {
                  bg: "bg-gokuOrange-300",
                  class: `fa-sharp fa-film`,
                },
                label: "Apply cinematic filters and add text overlays",
              },
              {
                icon: {
                  bg: "bg-yoshi_green-400",
                  class: `fa-sharp fa-up-down-left-right`,
                },
                label: "Adjust and reposition your byte",
              },
              {
                icon: {
                  bg: "bg-lotsoPink-300",
                  class: `fa-sharp fa-waveform-lines`,
                },
                label: "Clone or select voices from our library",
              },
              {
                icon: {
                  bg: "bg-jokerPurple-300",
                  class: `fa-sharp fa-images`,
                },
                label:
                  "Upload image and video assets or use our Gen-AI for image and video generation",
              },
              {
                icon: {
                  bg: "bg-sonicBlue-300",
                  class: `fa-sharp fa-folder-arrow-down`,
                },
                label:
                  "Download in multiple formats for easy social media distribution",
              },
            ].map((d, idx, list) => (
              <div
                key={idx}
                className={`flex items-center gap-[20px] ${
                  idx > 0 ? "border-t border-t-byteBlack-1000" : ""
                } ${
                  idx === 0
                    ? "pb-[16px] min-[1366px]:pb-[18px] [@media(max-height:768px)]:pb-[12px] [@media(min-height:768px)_and_(max-height:820px)]:pb-[14px]"
                    : idx === list.length - 1
                      ? "pt-[16px] min-[1366px]:pt-[18px] [@media(max-height:768px)]:pt-[12px] [@media(min-height:768px)_and_(max-height:820px)]:pt-[14px]"
                      : "py-[16px] min-[1366px]:py-[18px] [@media(max-height:768px)]:py-[12px] [@media(min-height:768px)_and_(max-height:820px)]:py-[14px]"
                }`}
              >
                <div
                  className={`relative flex h-[36px] w-[36px] shrink-0 items-center justify-center rounded-full xl:h-[40px] xl:w-[40px] min-[1366px]:h-[44px] min-[1366px]:w-[44px] [@media(max-height:768px)]:h-[32px] [@media(max-height:768px)]:w-[32px] [@media(min-height:768px)_and_(max-height:820px)]:h-[36px] [@media(min-height:768px)_and_(max-height:820px)]:w-[36px] ${d.icon.bg}`}
                >
                  <i aria-hidden className={d.icon.class} />
                </div>
                <p
                  className={`text-[16px] font-medium leading-[20px] xl:text-[18px] xl:leading-[24px] min-[1366px]:text-[20px] min-[1366px]:leading-[28px] [@media(max-height:768px)]:text-[16px] [@media(max-height:768px)]:leading-[20px]`}
                >
                  {d.label}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const SectionUseCaseC = ({ isAnimate }) => {
  const [, animate] = useAnimate();
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const isInView = useInView(titleRef, { once: true });
  isAnimate = isAnimate ?? isInView;

  useEffect(() => {
    if (isAnimate) {
      animate([
        [descRef.current, { opacity: [0, 1] }],
        [contentRef1.current, { opacity: [0, 1] }, { duration: 0.7 }],
        // [contentRef2.current, { opacity: [0, 1] }, { duration: 0.7 }],
      ]);
    }
  }, [isAnimate]);

  return (
    <>
      <SectionUseCaseHeader
        className="mb-[24px] px-4 pt-10 sm:px-[min(5%,64px)] md:mb-[min(86px,4vw)] lg:p-0"
        title={
          <span ref={titleRef}>
            <TextRotateFromBottom animate={isAnimate ? "animate" : ""}>
              VIDEO CALL AND LIVE-STREAMING
            </TextRotateFromBottom>
          </span>
        }
        desc={
          <p ref={descRef}>
            bythen&apos;s Livestreaming feature brings your Byte to life across
            video calls and streaming platforms.
          </p>
        }
      />

      <div className="relative h-full w-full [@media(max-height:768px)_and_(min-width:1366px)]:-mb-4">
        <div
          ref={contentRef1}
          className="no-scrollbar relative grid h-auto w-full grid-cols-[repeat(2,minmax(238px,1fr))] grid-rows-[minmax(0,1fr)_0fr] gap-x-6 gap-y-4 overflow-auto px-4 pb-10 sm:px-[min(5%,64px)] md:gap-x-40 lg:p-0"
        >
          <div className="relative md:max-h-[min(30vh,300px)]">
            <Image
              width={552}
              height={468}
              unoptimized
              src={`${ENVIRONMENT.ASSETS_URL}/landing/use-case/usecase-5.webp`}
              className="h-full w-full object-cover object-top"
              alt="img-section-c-1"
            />
          </div>
          <div className="relative md:max-h-[min(30vh,300px)]">
            <Image
              width={552}
              height={468}
              unoptimized
              src={`${ENVIRONMENT.ASSETS_URL}/landing/use-case/usecase-6.webp`}
              className="h-full w-full object-cover object-top"
              alt="img-section-c-2"
            />
          </div>

          {renderDesc(
            `User-Controlled Byte:`,
            `You can attend meeting and host livestream as your byte by simply using your phone or computer's camera with bythen app. Your byte will seamlessly mirror your every move`,
          )}
          {renderDesc(
            `AI-Powered Byte:`,
            `Go live 24/7 with an autonomous Byte that embodies your unique personality. Integrated with platform comment sections, your Byte can engage viewers directly and responding in real time`,
          )}
        </div>

        {/* <div
          ref={whiteFilterRef}
          className="pointer-events-none absolute bottom-0 left-0 h-full w-full select-none bg-white"
        /> */}
      </div>
    </>
  );
};

export default function SectionUseCase() {
  const sectionUseCaseRef = useSectionWithNavbar({ isBlack: true });
  const [currentActiveIndex, setCurrentActiveIndex] = useState(0);
  const [showContainer, setShowContainer] = useState(false);

  const { scrollYProgress } = useScroll({
    target: sectionUseCaseRef,
    offset: ["start start", "end end"],
  });

  const { scrollYProgress: scrollYProgressFade } = useScroll({
    target: sectionUseCaseRef,
    offset: ["start 40vh", "end end"],
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest < 1) {
      setCurrentActiveIndex(Math.floor(latest / (1 / 3)));
    }
  });

  useMotionValueEvent(scrollYProgressFade, "change", (latest) => {
    if (!showContainer) {
      setShowContainer(latest > 0);
    }
  });

  const scrollToSection = (currIndex) => {
    const BLOCK_POS = {
      0: "start",
      1: "center",
      2: "end",
    };

    if (sectionUseCaseRef.current) {
      sectionUseCaseRef.current.scrollIntoView({
        behavior: "smooth",
        block: BLOCK_POS[currIndex],
        inline: "center",
      });
    }
  };

  return (
    <div
      ref={sectionUseCaseRef}
      className="relative h-full bg-white lg:h-[calc(100vh*2)]"
    >
      <div className="relative lg:sticky lg:top-0 lg:overflow-hidden">
        <motion.div
          className="flex h-full w-full flex-col lg:h-screen"
          initial="initial"
          animate={showContainer ? "animate" : "initial"}
          variants={{
            initial: {
              opacity: 0,
            },
            animate: {
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            },
          }}
        >
          {/* <BythenLogoNav isBlack /> */}
          <div className="hidden h-[90px] lg:block" />
          <div className="h-[56px] w-full shrink-0 border-b border-t border-b-byteBlack-1000 border-t-byteBlack-1000">
            <p className="flex h-full w-[258px] items-center justify-center border-r border-r-byteBlack-1000 font-mono text-[14px] font-medium leading-[16px] lg:w-[354px] lg:text-[16px] lg:leading-[20px]">
              EMPOWERING THE DIGITAL FUTURE
            </p>
          </div>

          <div className="relative hidden h-full w-full lg:flex">
            <SectionContentContainer
              title="Video Call and Live-Streaming"
              number={3}
              scrollToSection={scrollToSection}
              currentActiveIndex={currentActiveIndex}
            >
              <SectionUseCaseC isAnimate={currentActiveIndex === 2} />
            </SectionContentContainer>
            <SectionContentContainer
              title="Content Generation Tool"
              number={2}
              scrollToSection={scrollToSection}
              currentActiveIndex={currentActiveIndex}
            >
              <SectionUseCaseB isAnimate={currentActiveIndex === 1} />
            </SectionContentContainer>
            <SectionContentContainer
              title="Autonomous Digital Twin"
              number={1}
              scrollToSection={scrollToSection}
              currentActiveIndex={currentActiveIndex}
            >
              <SectionUseCaseA
                isAnimate={showContainer && currentActiveIndex === 0}
              />
            </SectionContentContainer>
          </div>

          <div className="relative block h-full w-full overflow-hidden lg:hidden">
            <div className="border-b border-b-byteBlack-1000">
              <SectionUseCaseA />
            </div>
            <div className="border-b border-b-byteBlack-1000 p-4 pt-10 sm:px-[min(5%,64px)] sm:py-10">
              <SectionUseCaseB />
            </div>
            <div className="border-b border-b-byteBlack-1000">
              <SectionUseCaseC />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
