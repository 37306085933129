import fetchApi from "@/service/fetchApi";
import { ENVIRONMENT } from "@/utils/environment";

export async function getReferralCode(code) {
  return fetchApi(`ms/web3/v1/cards/validate_referral`, {
    method: "POST",
    body: {
      referral_code: code,
    },
  });
}

export function putTrialBytes(data) {
  return fetchApi(`ms/chat-bot/v1/bytes/trial`, {
    method: "PUT",
    body: data,
  });
}

export function postByteTrial(data) {
  return fetchApi(`ms/chat-bot/v1/bytes/trial`, { body: data, method: "POST" });
}

export function getTrialNftTokens({ symbol }) {
  return fetchApi(`ms/web3/v1/bytes/eligible/trial?symbol=${symbol}`);
}

export function getNftTokens({ symbol }) {
  return fetchApi(`ms/web3/v1/bytes/eligible/mint?symbol=${symbol}`);
}

export function getStaticPdp({ queryKey }) {
  const [_, slug] = queryKey || [];
  return fetchApi(`store/pdp/${slug}/data.json`, {
    customFetchUrl: `${ENVIRONMENT.ASSETS_URL}/store/pdp/${slug}/data.json`,
    isAuth: false,
  });
}

export function getTrialBytes() {
  const fetchData = async () => {
    try {
      const trialData = await fetchApi(`ms/chat-bot/v1/bytes/trial`, {
        method: "GET",
      });

      return {
        trial: trialData,
        slugs: [],
      };
    } catch (e) {
      throw e;
    }
  };

  return fetchData();
}

export function getStaticLandingStore() {
  return fetchApi(`store/landing.json`, {
    customFetchUrl: `${ENVIRONMENT.ASSETS_URL}/store/landing.json`,
    isAuth: false,
  });
}

export function getBythenCardData() {
  return fetchApi(`store/bythen-card/data.json`, {
    customFetchUrl: `${ENVIRONMENT.ASSETS_URL}/store/bythen-card/data.json`,
    isAuth: false,
  });
}

export function getBythenChipData() {
  return fetchApi(`store/bythen-chip/data.json`, {
    customFetchUrl: `${ENVIRONMENT.ASSETS_URL}/store/bythen-chip/data.json`,
    isAuth: false,
  });
}

export function getExpiredPoints() {
  return fetchApi(`ms/hive/v1/points/expired`);
}
