import Layout from "@/components/layout";
import { ENVIRONMENT } from "@/utils/environment";
import Head from "next/head";
import { BythenNavbarProvider } from "./landing/components/BythenNavbar/context";
import BythenNavbar from "./landing/components/BythenNavbar";
import SectionUseCase from "./landing/components/SectionUseCase";
import SectionProducts from "./landing/components/SectionProducts";
import HeroLanding from "./landing/components/HeroLanding";
import SectionIntroduce from "./landing/components/SectionIntroduce";
import SectionByteHive from "./landing/components/SectionByteHive";
import SectionTeam from "./landing/components/SectionTeam";
import SectionBackers from "./landing/components/SectionBackers";
import SectionBottom from "./landing/components/SectionBottom";
import { useEffect, useState } from "react";

const SectionLoadLater = () => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const toggleShow = () => {
      setIsShow((curr) => (!curr ? true : curr));
    };

    if (!isShow) {
      window.addEventListener("scroll", toggleShow);

      return () => {
        window.removeEventListener("scroll", toggleShow);
      };
    }
  }, [isShow]);

  return (
    <>
      {isShow && (
        <>
          <SectionUseCase />
          <SectionByteHive />
          <SectionTeam />
          <SectionBackers />
          <SectionBottom />
        </>
      )}
    </>
  );
};

export default function LandingPage() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "bythen",
    url: "https://bythen.ai",
    description:
      "Social-Fi Virtual Influencer Platform | Powering Creators with an AI powered creation tools & community that distribute revenue through a sharing-economy model",
    image: "https://assets.bythen.ai/landing/penguin-on-mobile.png",
    publisher: {
      "@type": "Organization",
      name: "Bythen",
      logo: {
        "@type": "ImageObject",
        url: "https://assets.bythen.ai/landing/logo_black.png",
      },
    },
  };
  return (
    <>
      <Head>
        <title>bythen</title>
        <meta
          name="description"
          content="Social-Fi Virtual Influencer Platform | Powering Creators with an AI powered creation tools & community that distribute revenue through a sharing-economy model"
        />
        <meta name="robots" content="index,follow" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:url" content={`${ENVIRONMENT.BASE_URL}`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image:alt"
          content="bythen - Collect as much energy possible to earn the greatest rewards."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="bythen" />

        <meta property="og:title" content="bythen" />
        <meta name="twitter:title" content="bythen" />
        <meta itemProp="name" content="bythen" />

        <meta
          property="og:description"
          content="#1 Creator-Fi Platform 🐝✨ — Elevating creators with AI-powered content tools, a revenue-sharing community and an advertising platform"
        />
        <meta
          name="twitter:description"
          content="#1 Creator-Fi Platform 🐝✨ — Elevating creators with AI-powered content tools, a revenue-sharing community and an advertising platform"
        />

        <meta
          property="og:image"
          content={`${ENVIRONMENT.ASSETS_URL}/landing/og-mainlp.png`}
        />
        <meta
          name="twitter:image"
          content={`${ENVIRONMENT.ASSETS_URL}/landing/og-mainlp.png`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />
        <meta property="og:image:type" content="image/png" />

        <meta
          itemProp="image"
          content={`${ENVIRONMENT.ASSETS_URL}/landing/og-mainlp.png`}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        />
      </Head>
      <Layout>
        <BythenNavbarProvider>
          <BythenNavbar />
          <HeroLanding />
          <SectionIntroduce />
          {/* <BythenCollection /> */}
          {/* <SectionProducts /> */}
          <SectionLoadLater />
        </BythenNavbarProvider>
      </Layout>
    </>
  );
}
