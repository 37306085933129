import { APP_ANDROID_LINK, APP_IOS_LINK } from "@/utils/app/link";

export const MENU_ITEMS_MOBILE = [
  {
    target: "/pod",
    name: "PRE ORDER BYTES",
  },
  {
    target: "https://bythen-ai.gitbook.io/bythen-ai",
    name: "WHITEPAPER",
  },
  {
    target: "/mycollection",
    name: "MY COLLECTION",
  },
  {
    target: "/studio",
    name: "STUDIO",
  },
];

export const NAV_MENU = [
  {
    target: "/store",
    name: "STORE",
  },
  {
    target: null,
    name: "BYTES HIVE",
    items: [
      {
        name: "MY HIVE",
        target: "/byteshive",
      },
      {
        name: "ABOUT BYTES HIVE",
        target: "/about-byteshive",
      },
    ],
  },
  {
    target: null,
    name: "DOWNLOADS",
    items: [
      {
        name: "FOR IOS",
        target: APP_IOS_LINK,
      },
      {
        name: "FOR ANDROID",
        target: APP_ANDROID_LINK,
      },
    ],
  },
  {
    target: null,
    name: "DOCS",
    items: [
      {
        name: "BYTHEN USER GUIDE",
        target: "https://bythen-ai.gitbook.io/userguide",
        newTab: true,
      },
      {
        name: "⁠BYTES HIVE GUIDE",
        target: "https://bythen-ai.gitbook.io/byteshive-guide",
      },
    ],
  },
];
