import { cn } from "@/utils/tailwind";

export default function SectionCarouselItem({
  children,
  onClick,
  backgroundUrl,
  className,
}) {
  return (
    <div
      className={cn(
        `keen-slider__slide aspect-[144/204] cursor-pointer bg-cover bg-center bg-no-repeat`,
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
