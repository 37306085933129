import Image from "next/image";
import SpinnerAsset from "@/assets/circular-spinner.webp";

function CircularSpinner({ size = 16 }) {
  return (
    <div className="animate-[spin_1s_ease-in-out_infinite]">
      <Image
        unoptimized
        width={size}
        height={size}
        src={SpinnerAsset.src}
        alt="circular spinner"
      />
    </div>
  );
}

export default CircularSpinner;
