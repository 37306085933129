import { motion } from "framer-motion";
import { useMemo } from "react";

const TextRotateFromBottom = ({
  children,
  index = 0,
  onAnimationComplete,
  classNames,
  ...props
}) => {
  const TextAnimationVariant = useMemo(
    () => ({
      animate: {
        opacity: [0, 0, 1],
        y: ["95%", "95%", "0%"],
        rotate: [5, 5, 0],
        transition: { duration: 0.6, ease: "linear", delay: index * 0.8 },
      },
    }),
    [index],
  );

  const handleAnimationComplete = () => {
    if (onAnimationComplete) {
      onAnimationComplete();
    }
  };
  return (
    <span className={`inline-block overflow-hidden ${classNames || ""}`}>
      <motion.span
        variants={TextAnimationVariant}
        animate="animate"
        className={`inline-block ${classNames || ""}`}
        onAnimationComplete={handleAnimationComplete}
        {...props}
      >
        {children}
      </motion.span>
    </span>
  );
};

export default TextRotateFromBottom;
