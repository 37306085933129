import { useCallback, useMemo } from "react";
import { ByteTrialContext } from "./context";
import { getTrialBytes } from "@/service/store";
import { useAuthContext } from "../auth/context";
import { useQuery } from "@tanstack/react-query";
import ByteTrialCountdownProvider from "../byteTrialCountdown/provider";

const ByteTrialProvider = ({ children, endTrialEntryPoint }) => {
  const { isAuthorized } = useAuthContext();

  const {
    data: trialData,
    isFetched: isTrialFetched,
    isLoading: isTrialLoading,
  } = useQuery({
    queryKey: ["getTrialBytes", isAuthorized],
    enabled: isAuthorized,
    queryFn: getTrialBytes,
    select: useCallback((data) => {
      const countdown = data?.trial?.trial_countdown || 0;
      const isTrialFinished = data?.trial?.is_trial_finished;
      const isModalEndShowed = data?.trial?.is_end_dialogue_displayed === false;
      return {
        ...data,
        countdown,
        is_free_trial_allowed: data?.trial?.account_id === 0,
        is_show_end_trial_modal: isTrialFinished && isModalEndShowed,
        is_in_trial: !isTrialFinished && countdown > 0,
      };
    }, []),
  });

  const value = useMemo(
    () => ({
      trialData,
      isFreeTrialAllowed: !!trialData?.is_free_trial_allowed,
      isTrialFetched,
      isTrialLoading,
    }),
    [isTrialFetched, isTrialLoading, trialData],
  );
  return (
    <ByteTrialContext.Provider value={value}>
      <ByteTrialCountdownProvider endTrialEntryPoint={endTrialEntryPoint}>
        {children}
      </ByteTrialCountdownProvider>
    </ByteTrialContext.Provider>
  );
};

export default ByteTrialProvider;
