/* eslint-disable @next/next/no-img-element */
import { closeModalDialog } from "@/components/layout";
import { useScreenContext } from "@/pages/mycollection/contexts/ScreenContext";
import { Image } from "@nextui-org/react";
import { useEffect } from "react";
import { useAccount } from "wagmi";
import { NAV_MENU } from "../const";
import BythenButton from "@/components/Button";
import { shortenWalletAddress } from "@/utils/shortenWalletAddress";
import MobileNavMenu from "./MobileNavMenu";
import { useAuthContext } from "@/contexts/auth/context";
import HiveIcon from "@/assets/hive/hive-icon.svg";
import { useByteTrialContext } from "@/contexts/byteTrial/context";

export default function MobileCollapseMenu({
  onClickChat,
  onClickStudio,
  onClickMyCollection,
  onClickNavMenu,
  onClickMyAccount,
}) {
  const { address } = useAccount();
  const { isDesktopWidth } = useScreenContext();
  const { isAuthorized, processLogout, authMeData, userProduct } =
    useAuthContext();
  const { trialData } = useByteTrialContext();

  const isShowChatRoom = trialData?.is_in_trial || userProduct?.bytes;

  useEffect(() => {
    if (isDesktopWidth) {
      closeModalDialog();
    }
  }, [isDesktopWidth]);

  return (
    <div className="w-full flex-col space-y-8">
      {/* Profile Account */}
      {isAuthorized && (
        <div>
          <div
            className="flex w-full cursor-pointer items-center space-x-2 pb-3"
            onClick={onClickMyAccount}
          >
            <Image
              className={`h-12 w-12 cursor-pointer rounded-full border border-byteBlack-1000/5 object-cover`}
              src={authMeData.profile_image_url}
              alt="profile-picture"
            />
            <div>
              <p className="mb-1 text-[14px] leading-4 tracking-wide text-byteBlack-1000">
                {authMeData?.username}
              </p>
              <div className="flex items-center space-x-1">
                <p className="font-mono_medium text-[12px] leading-4 text-byteBlack-1000/50">
                  {shortenWalletAddress(address)}
                </p>
                <i className="fa-sharp fa-regular fa-gear text-[12px] text-byteBlack-1000/50"></i>
              </div>
            </div>
          </div>
          <div
            className="flex cursor-pointer items-center justify-between rounded-[8px] border-1 border-byteBlack-1000/10 px-3 py-[14px]"
            onClick={onClickMyCollection}
          >
            <div className="mr-2 flex items-center">
              <i className="fa-sharp fa-folder-user mr-2 !text-[16px] text-black"></i>
              <span className="font-founders_semibold text-[16px] leading-5 tracking-[0.02px]">
                MY COLLECTION
              </span>
            </div>

            <span className="material-symbols-outlined !text-[24px] text-byteBlack-1000/50">
              chevron_right
            </span>
          </div>
        </div>
      )}

      {/* Menu Mobile */}
      <div className="flex flex-col space-y-4">
        {NAV_MENU.map((menu, index) => {
          return (
            <MobileNavMenu
              key={index}
              menu={menu}
              onClickNavMenu={onClickNavMenu}
            />
          );
        })}
        {isAuthorized && (
          <p
            role="button"
            className="font-founders_semibold text-[24px] uppercase leading-7 hover:text-[#FF5C0A]"
            onClick={processLogout}
          >
            DISCONNECT
          </p>
        )}
      </div>

      {/* Button Group */}
      <div id="test" className="flex flex-col space-y-3">
        {isAuthorized && (
          <>
            <div className="flex justify-between gap-3">
              <BythenButton
                classNames="w-full md:text-[16px]"
                type="white"
                onClickButton={onClickStudio}
              >
                <div className="mx-auto flex items-center space-x-[8px]">
                  <span className="material-symbols-outlined !text-[16px]">
                    videocam
                  </span>
                  <span>STUDIO</span>
                </div>
              </BythenButton>
              {isShowChatRoom && (
                <BythenButton
                  classNames="w-full md:text-[16px]"
                  type="white"
                  onClickButton={onClickChat}
                >
                  <div className="mx-auto flex items-center space-x-[8px]">
                    <span className="material-symbols-outlined !-mb-[2px] !text-[16px]">
                      chat_bubble
                    </span>
                    <span>CHATROOM</span>
                  </div>
                </BythenButton>
              )}
            </div>
            <BythenButton
              classNames="w-full md:text-[16px]"
              type="black"
              onClickButton={() => window.open("/byteshive", "_self")}
            >
              <div className="mx-auto flex items-center space-x-[8px]">
                <img
                  src={HiveIcon.src}
                  width={16}
                  height={16}
                  unoptimized
                  alt="hive icon navbar"
                />
                <span>MY HIVE</span>
              </div>
            </BythenButton>
          </>
        )}
      </div>
    </div>
  );
}
