import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { mainnet, polygon, sepolia } from "wagmi/chains";
import {
  metaMaskWallet,
  rabbyWallet,
  coinbaseWallet,
  zerionWallet,
  okxWallet,
  walletConnectWallet,
  trustWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { http } from "wagmi";

const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;

/*
Transports
- Polygon: https://chainlist.org/chain/137
- Mainnet: https://chainlist.org/chain/1
- Sepolia: https://chainlist.org/chain/11155111

*/
export const config = getDefaultConfig({
  appName: "Bythen",
  projectId: projectId,
  wallets: [
    {
      groupName: "Popular",
      wallets: [
        metaMaskWallet,
        rabbyWallet,
        coinbaseWallet,
        zerionWallet,
        okxWallet,
        walletConnectWallet,
      ],
    },
  ],
  chains: [mainnet, sepolia, polygon],
  transports: {
    [mainnet.id]: http("https://eth-pokt.nodies.app"),
    [sepolia.id]: http("https://sepolia.drpc.org"),
    [polygon.id]: http("https://polygon-rpc.com"),
  },
  multiInjectedProviderDiscovery: false,
  ssr: true,
});
