import { createContext, useContext } from "react";

export const ByteTrialContext = createContext({
  trialData: undefined,
  isFreeTrialAllowed: false,
  isTrialFetched: false,
  isTrialLoading: false,
});

export const useByteTrialContext = () => useContext(ByteTrialContext);
