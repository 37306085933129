import "@/styles/globals.css";
import "@rainbow-me/rainbowkit/styles.css";
import "keen-slider/keen-slider.min.css";
import localFont from "next/font/local";
import { Major_Mono_Display, DM_Mono } from "next/font/google";

import "regenerator-runtime/runtime";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import { config } from "@/utils/wagmi";
import WalletDisclaimer from "@/components/WalletDisclaimer";
import PageLoader from "@/components/Loader/PageLoader";
import { ReactLenis, useLenis } from "lenis/react";
import { useEffect, useState } from "react";

import Script from "next/script";
import { useRouter } from "next/router";

const neueMontreal = localFont({
  src: [
    {
      path: "../assets/fonts/NeueMontreal-Regular.otf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../assets/fonts/NeueMontreal-Medium.otf",
      weight: "500",
      style: "normal",
    },
  ],
  variable: "--font-neuemontreal",
});

const ITCAvantGarde = localFont({
  src: [
    {
      path: "../assets/fonts/ITCAvantGardeGothicProMedium.woff",
      weight: "400",
      style: "normal",
    },
  ],
  variable: "--font-itcavantgarde",
});

const MajorMono = Major_Mono_Display({
  weight: "400",
  subsets: ["latin"],
  variable: "--font-majormono",
});

const DMMono = DM_Mono({
  weight: ["400", "500"],
  subsets: ["latin"],
  variable: "--font-dmmono",
});

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH;
const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW;

const WALLET_STYLE = {
  fonts: {
    body: neueMontreal.style.fontFamily,
  },
};

export default function App({ Component, pageProps }) {
  const router = useRouter();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            gcTime: 0,
            staleTime: 0,
            retry: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      }),
  );

  useEffect(() => {
    if (GTM_ID) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
    }

    // Handle route changes for page views
    const handleRouteChange = (url) => {
      if (GTM_ID && window.dataLayer) {
        console.log("🚀 ~ handleRouteChange ~ url:", url);
        window.dataLayer.push({
          event: "virtual_pageview",
          page_path: url,
          page_title: document.title,
          page_location: window.location.href,
        });
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <style jsx global>{`
        :root {
          --blue: #1e90ff;
          --white: #ffffff;
        }

        html {
          font-family: ${neueMontreal.style.fontFamily};
        }

        .font-mono_medium {
          font-family: ${DMMono.style.fontFamily};
          font-weight: 500;
        }

        .font-mono_bold {
          font-family: ${DMMono.style.fontFamily};
          font-weight: 600;
        }
      `}</style>

      {GTM_ID && (
        <>
          <Script
            id="gtm-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_PREVIEW}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_ID}');
              `,
            }}
          />
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_PREVIEW}&gtm_cookies_win=x`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
        </>
      )}

      <PageLoader />

      <main
        className={`${ITCAvantGarde.variable} ${MajorMono.variable} ${DMMono.variable} font-sans`}
      >
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider
              appInfo={{
                appName: "Bythen",
                disclaimer: WalletDisclaimer,
              }}
              theme={{
                ...darkTheme(),
                ...WALLET_STYLE,
              }}
              modalSize="compact"
            >
              <ReactLenis root>
                <Component {...pageProps} />
              </ReactLenis>
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </main>
    </>
  );
}
