import { ENVIRONMENT } from "@/utils/environment";
import useSectionWithNavbar from "../hooks/useSectionWithNavbar";
import dynamic from "next/dynamic";

const PlayBoxVideo = dynamic(() => import("@/components/PlayBoxVideo"), {
  ssr: false,
});

function HeroLanding() {
  const sectionHero = useSectionWithNavbar({ isBlack: true });

  return (
    <div ref={sectionHero} className="relative min-h-screen w-full">
      <PlayBoxVideo
        playButtonClassName="md:h-[100px] md:w-[100px] md:text-[48px] text-[36px] h-[64px] w-[64px] [&_i]:ml-3"
        className="h-screen w-full"
        thumbnailDesktop={`${ENVIRONMENT.ASSETS_URL}/landing/video-poster-desktop.webp`}
        thumbnailMobile={`${ENVIRONMENT.ASSETS_URL}/landing/video-poster-mobile.webp`}
        videoSrc="https://storage.bythen.ai/bythen-cornerstone-nocard.webm"
      />

      <div className="absolute bottom-0 z-[2] flex h-[295px] w-full items-end bg-gradient-to-t from-byteBlack-1000/90">
        <div className="flex w-full flex-col items-center justify-between px-6 pb-10 md:flex-row md:pb-12">
          <h3 className="max-w-[850px] font-founders_semibold text-[32px] uppercase leading-none text-white md:text-[56px]">
            Your journey to becoming a virtual influencer starts here
          </h3>
        </div>
      </div>
    </div>
  );
}

export default HeroLanding;
