export const SectionUseCaseHeader = ({ className, title, desc }) => {
  return (
    <div className={`block lg:-mr-[1.25%] ${className}`}>
      <h1
        className={`font-founders_semibold text-[clamp(44px,min(calc((96/1440)*100vw),calc((96/1024)*100vh)),96px)] leading-[clamp(36px,min(calc((76/1440)*100vw),calc((76/1024)*100vh)),76px)] tracking-[0.01em]`}
      >
        {title}
      </h1>
      <div
        className={`mt-4 w-full text-[14px] leading-[20px] tracking-[0.02em] xl:text-[16px] xl:leading-[24px] min-[1366px]:text-[18px] min-[1366px]:leading-[28px] [@media(max-height:768px)]:text-[14px] [@media(max-height:768px)]:leading-[20px]`}
      >
        {desc}
      </div>
    </div>
  );
};
