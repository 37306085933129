import { APP_ANDROID_LINK, APP_IOS_LINK } from "@/utils/app/link";

export const FOOTER_MENUS = [
  {
    label: "my collection",
    href: "/mycollection",
  },
  {
    label: "chatroom",
    href: "/chat",
  },
  {
    label: "studio",
    href: "/studio",
  },
  {
    label: "user guide",
    href: "https://bythen-ai.gitbook.io/userguide",
  },
  {
    label: "term of use",
    href: "/termofuse",
  },
  {
    label: "privacy policy",
    href: "/privacypolicy",
  },
  {
    label: "DOWNLOAD FOR iOS",
    href: APP_IOS_LINK,
  },
  {
    label: "DOWNLOAD FOR Android",
    href: APP_ANDROID_LINK,
  },
];
