import { useCallback, useEffect, useState } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Image } from "@nextui-org/image";
import { resetPreconnectAction } from "@/signal/preconnect";
import ButtonNav from "../components/ButtonNav";
import { useAuthContext } from "@/contexts/auth/context";

const LoginMenu = ({ icon, name, onClick }) => {
  return (
    <div
      className="flex cursor-pointer items-center space-x-2 p-3"
      onClick={onClick}
    >
      {icon}
      <span className="font-founders_semibold text-[16px] leading-5 tracking-[0.02px] text-byteBlack-1000">
        {name}
      </span>
    </div>
  );
};

export default function BythenConnectWallet({
  isBlack,
  onClickMyCollection,
  onClickMyAccount,
}) {
  const [showAccount, setShowAccount] = useState(false);
  const {
    shortAddress,
    processLogout,
    isAuthorized,
    processLogin,
    authMeData,
    isAuthMeFetched,
  } = useAuthContext();

  const onShowAccount = useCallback(() => {
    setShowAccount(true);
  }, []);

  const onHideAccount = useCallback(() => {
    setShowAccount(false);
  }, []);

  useEffect(() => {
    if (!isAuthorized) {
      setShowAccount(false);
    }
  }, [isAuthorized]);

  const handleOpenConnectModal = () => {
    setShowAccount(false);
    resetPreconnectAction();
    processLogin();
  };

  if (isAuthorized) {
    return (
      <ConnectButton.Custom>
        {({ authenticationStatus, mounted }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== "loading";
          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
              className="relative hidden min-w-[44px] cursor-pointer justify-end lg:flex"
              onMouseEnter={onShowAccount}
              onMouseLeave={onHideAccount}
            >
              {(() => {
                return (
                  <div>
                    <div className="relative h-7 w-7 rounded-full">
                      {isAuthMeFetched && (
                        <Image
                          className="h-7 w-7 rounded-full border border-byteBlack-1000/5 object-cover"
                          src={authMeData.profile_image_url}
                          alt="profile-picture"
                        />
                      )}
                    </div>

                    {showAccount ? (
                      <div className="absolute right-0 top-full w-[256px]">
                        <div className="mt-4 w-full rounded-[8px] border-1 border-byteBlack-1000/30 bg-white shadow-[0px_8px_24px_0px_#0000000D]">
                          <div className="flex items-center space-x-2 border-b-1 border-byteBlack-1000/30 p-6">
                            <Image
                              className="h-12 w-12 rounded-full border border-byteBlack-1000/5 object-cover"
                              src={authMeData.profile_image_url}
                              alt="profile-picture"
                            />
                            <div>
                              <p className="text-[14px] leading-4 tracking-wide text-byteBlack-1000">
                                {authMeData.username}
                              </p>
                              <span className="font-mono text-[12px] leading-4 text-byteBlack-1000/50">
                                {shortAddress}
                              </span>
                            </div>
                          </div>

                          <div className="flex flex-col p-3">
                            <LoginMenu
                              icon={
                                <i className="fa-sharp fa-gear-complex !text-[16px] text-black"></i>
                              }
                              name="MY ACCOUNT"
                              onClick={onClickMyAccount}
                            />
                            <LoginMenu
                              icon={
                                <i className="fa-sharp fa-folder-user !text-[16px] text-black"></i>
                              }
                              name="MY COLLECTION"
                              onClick={onClickMyCollection}
                            />
                            <LoginMenu
                              icon={
                                <span className="material-symbols-outlined !text-[16px] text-black">
                                  logout
                                </span>
                              }
                              name="DISCONNECT"
                              onClick={processLogout}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    );
  }

  return (
    <ButtonNav
      isBlack={isBlack}
      onClick={handleOpenConnectModal}
      className="ml-3"
    >
      <span className="material-symbols-outlined !text-[16px]">wallet</span>
      <span>CONNECT WALLET</span>
    </ButtonNav>
  );
}
