import { useKeenSlider } from "keen-slider/react";
import { useState, Children, useCallback } from "react";

export default function SectionCarousel({ children }) {
  const totalChildren = Children.count(children);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    slides: {
      perView: 2,
      spacing: 0,
    },
    created() {
      setLoaded(true);
    },
  });

  const handlePrev = useCallback(
    (e) => {
      if (totalChildren <= 1 || !instanceRef.current) return;
      e.stopPropagation() || instanceRef.current?.prev();
    },
    [totalChildren, instanceRef],
  );

  const handleNext = useCallback(
    (e) => {
      if (totalChildren <= 1 || !instanceRef.current) return;
      e.stopPropagation() || instanceRef.current?.next();
    },
    [totalChildren, instanceRef],
  );

  return (
    <>
      <div className="mt-[40px] flex items-center justify-end border-t-[1px] border-white/10 px-6 text-white">
        {loaded && instanceRef.current && (
          <>
            {totalChildren > 1 && (
              <div className="mt-[-15px] border-[1px] border-white/10 bg-byteBlack-1000">
                <div className="flex space-x-2">
                  <div
                    onClick={handlePrev}
                    className="flex h-[28px] w-[28px] cursor-pointer items-center justify-end border-1 border-byteBlack-1000"
                  >
                    <span className="material-symbols-outlined text-[18px]">
                      arrow_back_ios
                    </span>
                  </div>
                  <div
                    onClick={handleNext}
                    className="flex h-[28px] w-[28px] cursor-pointer items-center justify-start border-1 border-byteBlack-1000"
                  >
                    <span className="material-symbols-outlined text-[18px]">
                      arrow_forward_ios
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div
        ref={sliderRef}
        className="keen-slider relative py-[80px]"
      >
        <>{children}</>
      </div>
    </>
  );
}
