import { useCallback, useEffect, useMemo, useState } from "react";
import { AuthContext, INITIAL_AUTH_ME_DATA } from "./context";
import { isClientUnauthorized, wagmiConnection } from "@/signal/layout";
import { bythenAccount, closeModalDialog } from "@/components/layout";
import { useQuery } from "@tanstack/react-query";
import { getAuthMe } from "@/service/auth";
import { postTrackAnalytics } from "@/service/tracking";
import { useRouter } from "next/router";
import { useAccount, useDisconnect } from "wagmi";
import Cookies from "js-cookie";
import { shortenWalletAddress } from "@/utils/shortenWalletAddress";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { bythenLogout } from "@/api/account";
import {
  COOKIE_HIVE_REFERRAL,
  getHiveReferralCookie,
} from "@/utils/hive/hiveReferralCookie";
import { getProductEligibility } from "@/service/web3/signature";

export const AuthContextProvider = ({ children }) => {
  const { access_token: authToken, wallet_address: walletAddress } =
    bythenAccount.value || {};
  const [authMeData, setAuthMeData] = useState(INITIAL_AUTH_ME_DATA);

  const { asPath, replace: routerReplace, query } = useRouter();
  const { disconnectAsync } = useDisconnect();
  const { openConnectModal } = useConnectModal();
  const { connector } = useAccount();

  const shortAddress = shortenWalletAddress(walletAddress);

  //Process logout from wallet
  const processLogout = useCallback(
    async (isResetQueryParams = true) => {
      try {
        await disconnectAsync(
          { connector },
          {
            onError(e) {
              console.log("🚀 ~ onDisconnect ~ error:", e);
            },
          },
        );
      } catch (e) {
        console.log("🚀 ~ onDisconnect ~ error:", e);
      } finally {
        const requestLogout = async () => {
          try {
            if (bythenAccount.value?.access_token) {
              const { access_token, account_id, account_type } =
                bythenAccount.value || {};
              await bythenLogout({
                access_token,
                account_id,
                account_type,
              });

              window.dataLayer.push({
                user_id: null,
              });
            }
          } catch (error) {
            console.log("🚀 ~ onDisconnect ~ error:", error);
          }
        };

        requestLogout();
        if (Cookies.get("BYTHEN_AUTH")) {
          //remove cookie if user already has old cookie
          Cookies.remove("BYTHEN_AUTH", {
            domain: ".bythen.ai",
            path: "/",
          });

          //remove new cookie
          Cookies.remove("BYTHEN_AUTH");
        }

        bythenAccount.value = "";
        if (isResetQueryParams) {
          //RESET REFERRAL HIVE
          const referralHive = getHiveReferralCookie();
          if (referralHive) {
            Cookies.remove(COOKIE_HIVE_REFERRAL);
            const { referral, ...restQuery } = query ?? {};
            routerReplace({ query: restQuery }, undefined, { shallow: true });
          }
        }
        closeModalDialog();
        wagmiConnection.value = false;
      }
    },
    [disconnectAsync, connector, routerReplace, query],
  );

  const processLogin = useCallback(async () => {
    await processLogout(false);
    openConnectModal();
  }, [processLogout, openConnectModal]);

  const isAuthorized = !!authToken && !isClientUnauthorized.value;
  const isUnauthorized = !isAuthorized;

  useEffect(() => {
    if (isClientUnauthorized.value) {
      processLogout();
      isClientUnauthorized.value = false;
    }
  }, [isClientUnauthorized.value, processLogout]);

  const {
    data: authMeResponse,
    isFetched: isAuthMeFetched,
    isLoading: isAuthMeLoading,
  } = useQuery({
    queryFn: getAuthMe,
    queryKey: ["getAuthMe", isAuthorized],
    enabled: isAuthorized,
    select: useCallback((data) => {
      const isValidTermOfUse = !(data?.agreement_approved_at === null);
      const isValidAccountSetup = !(data?.is_profile_completed === false);
      const profileImageUrl = data?.profile_image_url ?? "";
      const randomImage = data?.id ? (data.id % 3) + 1 : 1;

      return {
        ...data,
        tou_bythen_flag: isValidTermOfUse,
        account_setup_flag: isValidAccountSetup,
        profile_image_url: profileImageUrl
          ? profileImageUrl
          : `${process.env.NEXT_PUBLIC_ASSETS_URL}/general/profile-${
              randomImage
            }.png`,
      };
    }, []),
  });

  const { data: productEligibiltyResponse } = useQuery({
    queryFn: getProductEligibility,
    queryKey: ["getProductEligibility", isAuthorized],
    enabled: isAuthorized,
  });

  const userProduct = productEligibiltyResponse;

  useEffect(() => {
    if (authMeResponse && authMeResponse.id) {
      window.dataLayer.push({
        user_id: authMeResponse?.id,
      });
      postTrackAnalytics({
        account_id: authMeResponse?.id,
        platform: "web",
        page: asPath,
      });
    }
  }, [authMeResponse, asPath]);

  useEffect(() => {
    setAuthMeData(authMeResponse ? authMeResponse : INITIAL_AUTH_ME_DATA);
  }, [authMeResponse]);

  useEffect(() => {
    if (Cookies.get("BYTHEN_AUTH")) {
      window.dataLayer.push({
        user_id: JSON.parse(Cookies.get("BYTHEN_AUTH"))?.account_id,
      });
    }
  }, []);

  const value = useMemo(
    () => ({
      authToken,
      isUnauthorized,
      isAuthorized,
      shortAddress,
      walletAddress,
      isAuthMeFetched,
      isAuthMeLoading,
      authMeData,
      userProduct,
      processLogout,
      processLogin,
      setAuthMeData,
    }),
    [
      authToken,
      isUnauthorized,
      isAuthorized,
      shortAddress,
      walletAddress,
      isAuthMeFetched,
      isAuthMeLoading,
      authMeData,
      userProduct,
      processLogout,
      processLogin,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
