import { useMotionValueEvent, useScroll } from "framer-motion";
import { useRef } from "react";
import { useBythenNavbarContext } from "../components/BythenNavbar/context";

export const useScrollSection = (isBlack, setIsBlack) => {
  const ref = useRef();
  const { scrollYProgress: scrollYSection } = useScroll({
    target: ref,
    offset: ["-40px start", "end 40px"],
  });

  useMotionValueEvent(scrollYSection, "change", (latest) => {
    const isInView = latest >= 0;
    if (isInView) {
      setIsBlack(isBlack);
    }
  });
  return ref;
}

const useSectionWithNavbar = ({ isBlack }) => {
  const { setIsBlack } = useBythenNavbarContext();
  return useScrollSection(isBlack, setIsBlack);
};


export default useSectionWithNavbar;
