import Image from "next/image";
import { HIVE_POINTS } from "../../constants";

export default function HivePointsDesktop() {
  return (
    <div className="m-auto w-8/12 py-[88px]">
      <div className="flex justify-center text-white">
        {HIVE_POINTS.map((point, index) => (
          <div className="basis-1/4 px-[28px] text-center" key={index}>
            <Image
              unoptimized
              src={point.image}
              alt={point.text}
              className="m-auto pb-[12px]"
              width={120}
              height={120}
            />
            <p className="text-white">{point.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
