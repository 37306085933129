import { createContext, useContext } from "react";

export const INITIAL_AUTH_ME_DATA = {
  id: null,
  name: "",
  wallet_public_key: null,
  username: null,
  email: null,
  profile_image_url: `${process.env.NEXT_PUBLIC_ASSETS_URL}/general/profile-1.png`,
  twitter_username: null,
  telegram_username: null,
  discord_username: null,
  referral_code: null,
  agreement_approved_at: null,
  is_profile_completed: true,
  has_hive: false,
  tou_bythen_flag: null,
  account_setup_flag: null
};

export const AuthContext = createContext({
  authToken: null,
  isUnauthorized: false,
  isAuthorized: false,
  shortAddress: "",
  walletAddress: "",
  authMeData: INITIAL_AUTH_ME_DATA,
  isAuthMeFetched: false,
  isAuthMeLoading: false,
  processLogout: () => {},
  processLogin: () => {},
  setAuthMeData: (_ = INITIAL_AUTH_ME_DATA) => {},
  userProduct: {}
});

export const useAuthContext = () => useContext(AuthContext);
