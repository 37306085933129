import Image from "next/image";
import { ENVIRONMENT } from "@/utils/environment";
import BythenButton from "@/components/Button";

export default function ModalEndTrial({ onBuy, onClose, trialData }) {
  const { original_token_symbol } = trialData?.trial || {};
  return (
    <div className="h-full w-full">
      <div className="relative min-h-[200px] w-full">
        <Image
          src={`${ENVIRONMENT.ASSETS_URL}/store/bg-trial-end.webp`}
          fill
          alt=""
          unoptimized
          className="object-cover"
        />
        <span
          className="material-symbols-outlined absolute right-4 top-4 cursor-pointer text-[32px] text-white"
          onClick={onClose}
        >
          close_small
        </span>
      </div>
      <div className="flex flex-col items-start p-6">
        <p className="mb-3 font-founders_semibold text-[32px] uppercase leading-7">
          Your Free Trial for {original_token_symbol} Has Ended
        </p>
        <p className="text-[12px] leading-4 tracking-wide">
          To continue using your Byte and maintain your Bytes Hive membership,
          you need to purchase your bythen Pod.
        </p>
        <BythenButton
          type="black"
          classNames="w-full mt-6"
          onClickButton={onBuy}
        >
          <span className="mx-auto font-founders_semibold text-[16px] leading-[20px] tracking-wide">
            BUY NOW
          </span>
        </BythenButton>
      </div>
    </div>
  );
}
