import Image from "next/image";
import { motion } from "framer-motion";
import ArrowRightIcon from "@/components/Icon/ArrowRightIcon";
import Button from "@/components/Button";
import { ParallaxText } from "@/components/ParallaxText";
import { SectionIntroduceRunningTextItem } from "./components/SectionIntroduceRunningTextItem";
import useSectionWithNavbar from "../../hooks/useSectionWithNavbar";
import TextRotateFromBottom from "../Animation/TextRotateFromBottom";
import { ENVIRONMENT } from "@/utils/environment";
import { useEffect, useState } from "react";

export default function SectionIntroduce() {
  const sectionIntroduceRef = useSectionWithNavbar({ isBlack: false });
  const [isLoaded, setIsLoaded] = useState(false);

  const onOpenWhitePaper = () => {
    window.open("/store");
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div
      ref={sectionIntroduceRef}
      className="relative bg-byteBlack-1000"
      style={{
        "--title-size": "min(160px,max(calc((160/1440) * 100vw),56px))",
        "--title-leading": "min(120px,max(calc((120/1440) * 100vw),44px))",
        "--title-pt": "min(490px,max(calc((490/1440) * 100vw),340px))",
        "--title-pb": "min(36px,max(calc((36/1440) * 100vw),24px))",
        "--desc-mb": "min(88px,max(calc((88/1440) * 100vw),59px))",
      }}
    >
      <div className="relative">
        <Image
          fill
          unoptimized
          src={`${ENVIRONMENT.ASSETS_URL}/landing/section-3-bg.webp`}
          className="h-full w-full object-cover object-center"
          alt="img-animate-bg"
        />
        <div className="relative z-0">
          <p className="mx-auto w-full max-w-[1267px] pb-[var(--title-pb)] pt-[var(--title-pt)] text-center font-founders_semibold text-[length:--title-size] uppercase leading-[var(--title-leading)] tracking-[0.01px] text-gokuOrange-400">
            <TextRotateFromBottom
              whileInView="animate"
              viewport={{ once: true }}
            >
              Meet Bytes
            </TextRotateFromBottom>
          </p>
          <motion.div
            className="mx-4 flex flex-col items-center pb-[var(--desc-mb)] opacity-0 xs:mx-8 md:mx-auto"
            whileInView="animate"
            viewport={{ once: true }}
            variants={{
              animate: {
                opacity: 1,
                transition: { delay: 0.6 },
              },
            }}
          >
            <p className="w-full max-w-[570px] text-center text-[14px] leading-[20px] tracking-[0.02em] text-white md:max-w-[660px] md:text-[16px] md:leading-[24px] xl:max-w-[744px] xl:text-[18px] xl:leading-[28px]">
              bythen creates unique, ownable digital characters that empower
              users to build their pseudonymous social media persona, grow their
              IP, and attract followers. Our platform unlocks unlimited creative
              potential, enabling users to produce content, livestream, and
              transform their character into an AI-powered digital twin,
              elevating their online presence to the next level.
            </p>
            <Button
              type="black"
              classNames="w-full md:w-auto bg-transparent border-1 border-white text-white mt-8 py-[14px] px-[16px] md:py-[14px] md:px-[24px]"
              onClickButton={onOpenWhitePaper}
            >
              <span className="mr-[8px] font-founders_semibold text-[16px] leading-[20px] tracking-wide md:text-[20px] md:leading-[20px]">
                EXPLORE BYTES COLLECTION
              </span>
              <div className="relative flex aspect-square w-[24px] items-center justify-center">
                <ArrowRightIcon color="white" />
              </div>
            </Button>
          </motion.div>
        </div>
      </div>

      {isLoaded && (
        <ParallaxText baseVelocity={1} initialDirection={-1}>
          <div className="-mr-px flex h-[56px] items-center bg-[#FFAF70] md:h-[80px] md:space-x-[2px]">
            <SectionIntroduceRunningTextItem
              text="Pioneering AI NFT PFPs"
              icon={
                <SectionIntroduceRunningTextItem.Icon
                  src={`${ENVIRONMENT.ASSETS_URL}/landing/section_2_ico_user_circle.png`}
                />
              }
            />
            <SectionIntroduceRunningTextItem
              text="TOMORROW'S WORLD STARTS TODAY"
              icon={
                <SectionIntroduceRunningTextItem.Icon
                  src={`${ENVIRONMENT.ASSETS_URL}/landing/section_2_ico_world.png`}
                />
              }
            />
            <SectionIntroduceRunningTextItem
              text="Sharing economy model"
              icon={
                <SectionIntroduceRunningTextItem.Icon
                  src={`${ENVIRONMENT.ASSETS_URL}/landing/section_2_circle_dollar.png`}
                  className="mix-blend-multiply"
                />
              }
            />
          </div>
        </ParallaxText>
      )}
    </div>
  );
}
